import React from "react";
import { UseMediaQuery } from "../../hooks/useMediaQuery";
import { NavLink } from "react-router-dom";

function AdminMenu() {
  const matches = UseMediaQuery("(max-width: 992px)");
  return (
    <div>
      {matches ? (
        ""
      ) : (
        <div className="bg-white border-r border-grey7  h-screen pt-5 pl-3 pr-3 min-w-[250px]">
          <div className="">
            <img alt="logo" src="img/logo.png" />
          </div>
          <div className="mt-8 deskMenu">
            <NavLink
              className="w-full block commonA"
              exact
              activeClassName="active"
              to="/"
            >
              <span className="inline commonMenu homeMenu">s</span>
              <span className="ml-3">Home</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/adminDashboard"
            >
              <span className="inline commonMenu dashMenu">s</span>
              <span className="ml-3">Dashboard</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/myPost"
            >
              <span className="inline commonMenu postMenu">s</span>
              <span className="ml-3">My Post</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/category"
            >
              <span className="inline commonMenu dashMenu">s</span>
              <span className="ml-3">Category</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/adminReport"
            >
              <span className="inline commonMenu taxMenu">s</span>
              <span className="ml-3">Service Report</span>
            </NavLink>
            <NavLink
              className="w-full block mt-4 commonA"
              exact
              activeClassName="active"
              to="/professionalsAdmin"
            >
              <span className="inline commonMenu taxMenu">s</span>
              <span className="ml-3">Professionals List</span>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminMenu;
