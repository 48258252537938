import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("userToken");
const moment = require("moment");

export function getDateFormate(dateString) {
  //const dateString = "2024-01-31T11:21:34.349Z"; // input date format

  // Convert the string to a Date object
  const date = new Date(dateString);

  // Days of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Months
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date components
  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const dayOfMonth = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  // Format the date string
  const formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month}, ${year}`;
  // out put : Wed, 31 Jan, 2024
  return formattedDate;
}

export function convertTime(start_time) {
  //input : 10:00 AM or 8:00 PM
  if (start_time) {
    const convertedTime = new Date();
    console.log(start_time, "sttt");
    const [time, period] = start_time.split(" ");
    let [hours, minutes] = time.split(":");

    // Convert hours to 24-hour format if the period is PM
    if (period === "PM" && hours !== "12") {
      hours = parseInt(hours, 10) + 12;
    }

    // Convert 12 AM to 00 hours
    if (period === "AM" && hours === "12") {
      hours = "00";
    }
    return convertedTime.setHours(
      parseInt(hours, 10),
      parseInt(minutes, 10),
      0
    );
  }
  // output : 1706688000038
}

export function convertTimeString(startTime) {
  //input string - startTime
  const originalDate = new Date(startTime);
  const formattedTime = moment(originalDate).format("h:mm A");
  // Output: 3:30 PM
  return formattedTime;
}

export function convertDate(dateString) {
  //const dateString = "2024-02-05T11:20:46.101Z"; // input

  // Convert the string to a Date object
  const date = new Date(dateString);

  // Extract date components
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth(); // Note: Months are zero-based (0 = January, 1 = February, ...)
  const day = date.getUTCDate();

  // Create an array with year, month, and day
  const dateArray = [year, month, day];
  // output [2024, 1, 5]
  return dateArray;
}

export function convertTimeHourMinute(timeString) {
  //const timeString = "01:00 PM"; //input string

  // Split the time string into hours and minutes
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":");

  // Convert hours to 24-hour format
  let hours24 = parseInt(hours, 10);
  if (period === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (period === "AM" && hours24 === 12) {
    hours24 = 0;
  }
  return [hours24, parseInt(minutes, 10)];
}

export function calculateEndTime1(time, duration) {
  // Original time
  const originalTime = new Date();
  let [hours1, minutes1] = time.split(":").map((part) => parseInt(part));
  originalTime.setHours(hours1, minutes1, 0); // Set the original time to 10:00 AM

  // Adding duration
  const newTime = new Date(originalTime.getTime() + duration * 60000); // 45 minutes in milliseconds

  // Formatting the new time
  const hours = newTime.getHours();
  const minutes = newTime.getMinutes();
  const amOrPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${amOrPm}`;

  return formattedTime;
}

export function calculateEndTime(time) {
  // Convert time to hours and minutes
  let [hours, minutes] = time.split(":").map((part) => parseInt(part));
  let isPM = time.includes("PM");

  // Add 60 minutes
  minutes += 60;
  // Handle overflow if minutes exceed 60
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes %= 60;
  }

  // Handle overflow if hours exceed 12 (12:00 PM)
  if (hours >= 12) {
    hours %= 12;
    isPM = !isPM; // Toggle AM/PM
  }

  // Convert back to string format
  let newTime = `${hours}:${minutes.toString().padStart(2, "0")} ${
    isPM ? "PM" : "AM"
  }`;

  return newTime;
}

export function logout() {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userInfo");
  window.location.href = "/";
}

export async function checkExpToken() {
  if (token) {
    try {
      const response = await axios.get(
        API_URL + "post/get-all-professional-post?limit=1&page=" + 1,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        return true;
      }
    } catch (error) {
      console.error("Error fetching data :", error);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 401
      ) {
        logout();
      }
    }
  }
}

// Function to replace HTTP links with <a> tags
export function replaceLinks(inputText) {
  const linkRegex = /(http[s]?:\/\/\S+)/gi;
  return inputText.replace(linkRegex, (match) => {
    return `<a style="color:#5162BC" href="${match}" target="_blank">${match}</a>`;
  });
}

export const generateRandomEmail = () => {
  const domains = ["example.com", "test.com", "demo.org", "mail.net"];
  const randomString = Math.random().toString(36).substring(2, 10); // Generate a random string
  const randomDomain = domains[Math.floor(Math.random() * domains.length)]; // Pick a random domain
  return `${randomString}@${randomDomain}`;
};
