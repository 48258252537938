import React, { useState, useEffect } from "react";
import { UseMediaQuery, isNumber } from "../hooks/useMediaQuery";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Model from "./model";
import axios from "axios";
import Logo from "./logo";
import "../css/menu.css";
import SocialIcons from "./socialIcons";
import Spinner from "./spinner";
import Interest from "./interest";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { logout } from "../hooks/commonFunctions";

function OuterMenu({ page, cartClr = "" }) {
  const [activeMenu, setActiveMenu] = useState("home");
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };
  const matches = UseMediaQuery("(max-width: 992px)");

  const [modalIsOpenSignIn, setIsOpenSignIn] = useState(false);
  const [modalIsOpenForgot, setIsOpenForgot] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpenInterest, setModalIsOpenInterest] = useState(false);
  const [isOpen, menuIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsLogin, setErrorsLogin] = useState({});
  const [errorsForgot, setErrorsForgot] = useState({});
  const [userIdForgot, setUserIdForgot] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    confirmpassword: "",
    user_type: "professional",
  });
  const [formDataLogin, setFormDataLogin] = useState({
    identifier: "",
    password: "",
  });
  const [formDataForgot, setFormDataForgot] = useState({
    email: "",
    password: "",
    cpassword: "",
    otp: "",
  });

  const [loginFailError, setLoginFailError] = useState("");
  const [forgotFailError, setForgotFailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpVerify, setOtpVerify] = useState(false);
  const [loadingForgot, setLoadingForgot] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [successForgot, setSuccessForgot] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : "";

  useEffect(() => {
    const finishLoading = () => {
      // Set loading to false once the page is fully loaded
      setLoading(false);
    };

    // Listen to the load event on the window
    window.addEventListener("load", finishLoading);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener("load", finishLoading);
    };
  }, []);

  const validateFormForgotOtp = () => {
    const errors = {};

    if (!formDataForgot.otp.trim()) {
      errors.otp = "Otp is required";
    }

    if (!formDataForgot.password) {
      errors.password = "Password is required";
    } else if (formDataForgot.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (!formDataForgot.cpassword) {
      errors.cpassword = "Password is required";
    }

    if (formDataForgot.password !== formDataForgot.cpassword) {
      errors.cpassword = "Passwords do not match";
    }

    setErrorsForgot(errors);
    return Object.keys(errors).length === 0;
  };

  const validateFormForgot = () => {
    const errors = {};

    if (!formDataForgot.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formDataForgot.email)) {
      errors.email = "Invalid email address";
    }

    setErrorsForgot(errors);
    return Object.keys(errors).length === 0;
  };

  const validateFormLogin = () => {
    const errors = {};

    if (!formDataLogin.identifier.trim()) {
      errors.identifier = "Email or Phone number is required";
    }
    // else if (!/\S+@\S+\.\S+/.test(formDataLogin.identifier)) {
    //   errors.identifier = "Invalid email address";
    // }

    if (!formDataLogin.password) {
      errors.password = "Password is required";
    }

    setErrorsLogin(errors);
    return Object.keys(errors).length === 0;
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.first_name.trim()) {
      errors.first_name = "First Name is required";
    }

    if (!formData.last_name.trim()) {
      errors.last_name = "Last Name is required";
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = "Mobile No is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!formData.password) {
      errors.password = "Password is required";
    } else if (formData.password.length < 6) {
      errors.password = "Password must be at least 6 characters long";
    }

    if (formData.password !== formData.confirmpassword) {
      errors.password = "Passwords do not match";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangeLogin = (e) => {
    const { name, value } = e.target;
    setFormDataLogin({ ...formDataLogin, [name]: value });
  };

  const handleChangeForgot = (e) => {
    const { name, value } = e.target;
    setFormDataForgot({ ...formDataForgot, [name]: value });
  };

  const handleSubmitForgot = async (e) => {
    e.preventDefault();
    if (otpVerify) {
      const isValid = validateFormForgotOtp();
      if (isValid) {
        setLoadingForgot(true);
        let data = {};
        data["otp"] = formDataForgot["otp"];
        data["password"] = formDataForgot["password"];
        try {
          await axios
            .put(API_URL + "user/verify-otp-forgot/" + userIdForgot, data)
            .then(function (response) {
              setSuccessForgot(true);
              //setOtpVerify(false);
              setLoadingForgot(false);
            })
            .catch(function (error) {
              setLoadingForgot(false);
              setForgotFailError(
                error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Password not changed"
              );
              console.log(error, "error");

              //setOtpVerify(false);
            });
        } catch (error) {
          setOtpVerify(false);
          console.error("Error:", error);
        }
      }
    } else {
      const isValid = validateFormForgot();
      if (isValid) {
        setLoadingForgot(true);

        try {
          await axios
            .put(API_URL + "user/forgot-password/" + formDataForgot["email"])
            .then(function (response) {
              setUserIdForgot(response.data.user_id);
              setOtpVerify(true);
              setLoadingForgot(false);
              setForgotFailError(false);
            })
            .catch(function (error) {
              setLoadingForgot(false);
              setForgotFailError(
                error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "Mail not sent"
              );
              console.log(error, "error");
            });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();

    const isValid = validateFormLogin();
    if (isValid) {
      setLoading(true);
      try {
        await axios
          .post(API_URL + "user/login", formDataLogin, {
            headers: {
              //"Content-Type": "multipart/form-data",
              // Add any other necessary headers
            },
          })
          .then(function (response) {
            localStorage.setItem("userToken", response.data.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.user)
            );
            setLoading(false);

            closeModalSignIn();
            if (
              response.data.user.userOnboardedStatus === false &&
              response.data.user.user_type === "professional"
            ) {
              openOnboardLink(
                response.data.user.user_id,
                response.data.user.boarding_url
              );
            }
            // if (response.data.user.interest_status) {
            if (response.data.user.user_type === "professional") {
              //navigate("/professionalDashboard");
              navigate("appointment");
            } else if (response.data.user.user_type === "admin") {
              navigate("/adminDashboard");
            } else {
              navigate("/enthusiastDashboard");
            }
            // } else {
            //   setModalIsOpenInterest(true);
            // }
          })
          .catch(function (error) {
            setLoginFailError("Wrong Email Or Password, Please try again.");
            console.log(error, "error");
            setLoading(false);
          });
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const openOnboardLink = async (id, link) => {
    try {
      const response1 = await axios
        .get(API_URL + "user/boarding-link/" + id, {
          headers: {
            //"Content-Type": "multipart/form-data",
            // Add any other necessary headers
          },
        })
        .then(function (response1) {
          if (response1.data.boarding_url) {
            window.open(response1.data.boarding_url, "_blank");
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (isValid) {
      setLoadingSignUp(true);
      try {
        formData["profile_name"] = (
          formData.first_name +
          formData.last_name.charAt(0).toUpperCase() +
          formData.last_name.slice(1)
        ).replace(/\s/g, "");
        await axios
          .post(API_URL + "user/signup", formData, {
            headers: {
              //"Content-Type": "multipart/form-data",
              // Add any other necessary headers
            },
          })
          .then(function (response) {
            if (response.data.user.boarding_url) {
              window.open(response.data.user.boarding_url, "_blank");
            }
            setIsOpen(false);
            localStorage.setItem("userToken", response.data.token);
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.user)
            );
            //if (response.data.user.interest_status) {
            if (response.data.user.user_type === "professional") {
              //navigate("/professionalDashboard");
              navigate("appointment");
            } else if (response.data.user.user_type === "admin") {
              navigate("/adminDashboard");
            } else {
              navigate("/enthusiastDashboard");
            }
            // } else {
            //   setModalIsOpenInterest(true);
            // }
            setLoadingSignUp(false);
          })
          .catch(function (error) {
            setSignUpError(error.response.data.message);
            console.log(error);
            setLoadingSignUp(false);
          });
      } catch (error) {
        setLoadingSignUp(false);
        console.error("Error:", error);
      }
    }
  };

  const handleType = (type) => {
    setFormData({ ...formData, user_type: type });
  };

  function openModalSignIn(event) {
    event.preventDefault();
    menuIsOpen(false);
    setIsOpenSignIn(true);
    setIsOpen(false);
    setIsOpenForgot(false);
  }

  function closeModalSignIn() {
    setIsOpenSignIn(false);
  }

  function closeModalForgot() {
    setIsOpenForgot(false);
    setForgotFailError(false);
    setUserIdForgot("");
    setLoadingForgot(false);
  }

  function closeModalInterest() {
    setModalIsOpenInterest(false);
  }

  function openModal() {
    setIsOpen(true);
    setIsOpenSignIn(false);
    setIsOpenForgot(false);
  }

  function openModalForgot() {
    setIsOpenForgot(true);
    setIsOpen(false);
    setIsOpenSignIn(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const content = () => {
    return (
      <>
        <div className={`${matches ? "" : "grid grid-cols-2 gap-2"}`}>
          {!matches ? (
            <img alt="signup" src="img/signup.png" className="w-full h-full" />
          ) : (
            ""
          )}
          <div className="px-2.5">
            <Logo />
            <div className="grid grid-cols-2 mt-4 border border-grey1 rounded p-1">
              <button
                onClick={() => handleType("professional")}
                className={` ${
                  formData.user_type === "professional"
                    ? "bg-green1 rounded text-white"
                    : ""
                } lg:grid-cols-2 md:grid-cols-2 sm-lg:grid-cols-1 xs:lg:grid-cols-1 xxs:lg:grid-cols-1 p-2`}
              >
                Are you professional?
              </button>
              <button
                onClick={() => handleType("enthusiastic")}
                className={` ${
                  formData.user_type === "enthusiastic"
                    ? "bg-green1 rounded text-white"
                    : ""
                } lg:grid-cols-2 md:grid-cols-2 sm-lg:grid-cols-1 xs:lg:grid-cols-1 xxs:lg:grid-cols-1 p-2`}
              >
                Are you enthusiast?
              </button>
            </div>
            {/* <SocialIcons /> */}

            <div className="mt-4 ">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="text-grey3 text-sm">First Name*</label>
                    <input
                      type="text"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                      placeholder="Enter your first name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    {errors.first_name && (
                      <span className="text-red text-xs">
                        {errors.first_name}
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="text-grey3 text-sm">Last Name*</label>
                    <input
                      type="text"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                      placeholder="Enter your last name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                    {errors.last_name && (
                      <span className="text-red text-xs">
                        {errors.last_name}
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-2.5">
                  <div>
                    <label className="text-grey3 text-sm">Mobile No*</label>
                    <div>
                      <div className="grid grid-cols-5 gap-2">
                        <input
                          type="text"
                          className="col-span-1 w-10 border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm rounded"
                          placeholder="+1"
                        />
                        <input
                          type="text"
                          className="col-span-4 border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                          placeholder="Enter mobile number"
                          name="phone_number"
                          value={formData.phone_number}
                          onChange={handleChange}
                          onKeyDown={(event) => isNumber(event)}
                        />
                      </div>
                      {errors.phone_number && (
                        <span className="text-red text-xs">
                          {errors.phone_number}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label className="text-grey3 text-sm w-full">Email*</label>
                    <input
                      type="text"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                      placeholder="Enter your email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="text-red text-xs">{errors.email}</span>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-2.5">
                  <div>
                    <label className="text-grey3 text-sm">Password*</label>
                    <input
                      type="password"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span className="text-red text-xs">
                        {errors.password}
                      </span>
                    )}
                  </div>
                  <div>
                    <label className="text-grey3 text-sm">
                      Confirm Password*
                    </label>
                    <input
                      type="password"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                      placeholder="Confirm Password"
                      name="confirmpassword"
                      value={formData.confirmpassword}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                {loadingSignUp ? (
                  <Spinner /> // Show the spinner while loading
                ) : (
                  <>
                    {signUpError ? (
                      <div className="mt-3 text-red text-center">
                        {signUpError}
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="submit"
                      value="Sign Up"
                      name="Sign Up"
                      className="font-bold bg-green1 text-white w-full !py-3 px-5 text-base mt-4 rounded shadowEffect hover:opacity-60"
                    />
                  </>
                )}
              </form>

              <div className="mt-3 text-base text-center">
                <span className="text-black1  font-normal ">
                  Already Have An Account?
                </span>

                <a
                  href="/#"
                  onClick={openModalSignIn}
                  className="text-green1 font-bold"
                >
                  {" "}
                  Log In
                </a>
                <div className="text-black2">
                  <span className="font-bold">Create a page</span> for a
                  celebrity, brand or buisness
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const contentSignIn = () => {
    return (
      <>
        <div className={`${matches ? "" : "grid grid-cols-2 gap-2"}`}>
          {!matches ? (
            <img alt="signup" src="img/signup.png" className="w-full h-full" />
          ) : (
            ""
          )}
          <div>
            <Logo />
            {/* <SocialIcons /> */}

            <div className="mt-4 px-2.5">
              <Form onSubmit={handleSubmitLogin}>
                <label className="text-grey3 text-sm w-full">
                  Email or Phone
                </label>
                <input
                  type="text"
                  className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded "
                  placeholder="Enter your email or phone number"
                  name="identifier"
                  value={formDataLogin.identifier}
                  onChange={handleChangeLogin}
                />
                {errorsLogin.identifier && (
                  <span className="text-red text-xs">
                    {errorsLogin.identifier}
                  </span>
                )}

                <div className="mt-2.5">
                  <label className="text-grey3 text-sm">Password</label>
                  <input
                    type="password"
                    className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded"
                    placeholder="Password"
                    name="password"
                    value={formDataLogin.password}
                    onChange={handleChangeLogin}
                  />
                  {errorsLogin.password && (
                    <span className="text-red text-xs">
                      {errorsLogin.password}
                    </span>
                  )}
                </div>
                {loginFailError ? (
                  <div className="text-red text-xs text-center mt-3">
                    {loginFailError}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {loading ? (
                    <Spinner /> // Show the spinner while loading
                  ) : (
                    <input
                      type="submit"
                      value="Login"
                      name="Login"
                      className="font-bold bg-green1 text-white w-full !py-3 px-5 text-base mt-4 rounded shadowEffect hover:opacity-60"
                    />
                  )}
                </div>

                <div className="mt-3">
                  <Form.Check
                    className="text-grey3 inline-block"
                    type="checkbox"
                    label="Remeber Me"
                  />

                  <a
                    onClick={openModalForgot}
                    href="/#"
                    className="text-blue1 text-sm font-medium float-right"
                  >
                    Forgot Password?
                  </a>
                </div>
              </Form>

              <div className="mt-3 text-base text-center">
                <span className="text-black1  font-normal ">
                  Don't Have An Account?
                </span>
                <a
                  className="text-green1 font-bold ml-1"
                  href="/#"
                  onClick={openModal}
                >
                  Sign Up Now
                </a>
                <div className="text-black2">
                  <span className="font-bold">Create a page</span> for a
                  celebrity, brand or buisness
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const contentForgot = () => {
    return (
      <>
        <div className={`${matches ? "" : "grid grid-cols-2 gap-2"}`}>
          {!matches ? (
            <img alt="signup" src="img/signup.png" className="w-full h-full" />
          ) : (
            ""
          )}
          <div>
            <Logo />
            {/* <SocialIcons /> */}

            <div className="mt-4 px-2.5">
              <Form onSubmit={handleSubmitForgot}>
                {otpVerify ? (
                  <div>
                    <div className="mb-1 text-grey3 text-center">
                      Please check your mail and enter OPT here..
                    </div>

                    <label className="text-grey3 text-sm w-full">OTP</label>
                    <input
                      type="otp"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded "
                      placeholder="Enter your otp"
                      name="otp"
                      value={formDataForgot.otp}
                      onChange={handleChangeForgot}
                    />
                    {errorsForgot.otp && (
                      <span className="text-red text-xs">
                        {errorsForgot.otp}
                      </span>
                    )}

                    <label className="mt-2 text-grey3 text-sm w-full">
                      New Password
                    </label>
                    <input
                      type="password"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded "
                      placeholder="Enter your password"
                      name="password"
                      value={formDataForgot.password}
                      onChange={handleChangeForgot}
                    />
                    {errorsForgot.password && (
                      <span className="text-red text-xs">
                        {errorsForgot.password}
                      </span>
                    )}

                    <label className="mt-2 text-grey3 text-sm w-full">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded "
                      placeholder="Confirm your password"
                      name="cpassword"
                      value={formDataForgot.cpassword}
                      onChange={handleChangeForgot}
                    />
                    {errorsForgot.cpassword && (
                      <span className="text-red text-xs">
                        {errorsForgot.cpassword}
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    <label className="text-grey3 text-sm w-full">Email</label>
                    <input
                      type="email"
                      className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-sm w-full rounded "
                      placeholder="Enter your email"
                      name="email"
                      value={formDataForgot.email}
                      onChange={handleChangeForgot}
                    />
                    {errorsForgot.email && (
                      <span className="text-red text-xs">
                        {errorsForgot.email}
                      </span>
                    )}
                  </div>
                )}

                {forgotFailError ? (
                  <div className="text-red text-xs text-center mt-3">
                    {forgotFailError}
                  </div>
                ) : (
                  ""
                )}

                <div>
                  {loadingForgot ? (
                    <Spinner /> // Show the spinner while loading
                  ) : (
                    <input
                      type="submit"
                      value="Reset Password"
                      name="Reset Password"
                      className="font-bold bg-green1 text-white w-full !py-3 px-5 text-base mt-4 rounded shadowEffect hover:opacity-60"
                    />
                  )}
                </div>
              </Form>

              {successForgot ? (
                <div className="text-green1 text-center mt-3 text-sm">
                  Password Changed successfully, now you can login with new
                  password
                </div>
              ) : (
                ""
              )}

              <div className="mt-3 text-base text-center">
                <a
                  className="text-green1 font-bold ml-1"
                  href="/#"
                  onClick={openModalSignIn}
                >
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleLogout = async () => {
    logout();
  };

  return (
    <div className="xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-10">
      {/* <img src="img/home.png" /> */}
      <div className="grid grid-cols-6">
        <div className="col-span-2 xl:col-span-2 lg:col-span-2 lg3:col-span-1  flex ">
          <a href="/" onClick={() => handleMenuClick("home")}>
            <img
              alt="logo"
              src={`${page === "other" ? "img/logo.png" : "img/logoW.png"}`}
            />
          </a>
        </div>
        {matches ? (
          <>
            {/* <a href="/wishlist">
              <img
                src="img/wishlist.png"
                className="inline-block h-[25px] w-[25px] mr-1 align-baseline"
              />
            </a>
            <a href="/paymentSummary">
              <img
                src="img/cart.png"
                className="inline-block h-[20px] w-[20px] mr-5 align-super"
              />
            </a> */}

            <div className="">
              <div className="cartMenu">
                <a href="/wishlist">
                  <img
                    // src="img/wishlist.png"
                    src={` ${
                      cartClr === "black"
                        ? "img/wishlist.png"
                        : "img/White-Heart.png"
                    } `}
                    className="inline-block h-[25px] w-[25px] mr-1 align-baseline"
                  />
                </a>
                <a href="/cart">
                  <img
                    src={` ${
                      cartClr === "black" ? "img/cart.png" : "img/cartW.jpg"
                    } `}
                    className="inline-block h-[20px] w-[20px] mr-5 align-baseline"
                  />
                </a>
              </div>
              <div className="burgerMenu" onClick={() => menuIsOpen(true)}>
                <button className="btnMenu"></button>
                <span className="menuSpan first"></span>
                <span className="menuSpan second"></span>
                <span className="menuSpan third"></span>
              </div>
              {isOpen ? (
                <div className="menuWrap">
                  <div>
                    <div className="crossMenu">
                      <button onClick={() => menuIsOpen(false)}>close</button>
                      <span>
                        <span className="crossSpan"></span>
                        <span className="crossSpan2"></span>
                      </span>
                    </div>
                  </div>
                  <div className="menuInner">
                    <div className="menuList">
                      <NavLink
                        to="/"
                        activeClassName="activeMenu"
                        className="menu-item"
                      >
                        Home
                      </NavLink>
                      {/* <a
                        id="home"
                        className={` ${
                          activeMenu === "home" ? "activeMenu" : ""
                        } menu-item`}
                        href="/"
                      >
                        Home
                      </a> */}
                      <a id="home" className="menu-item mt-3" href="/">
                        Services
                      </a>
                      <NavLink
                        to="/professionals"
                        activeClassName="activeMenu"
                        className="menu-item mt-3"
                      >
                        Professionals
                      </NavLink>

                      <a id="home" className="menu-item mt-3" href="/">
                        Contact Us
                      </a>
                      <a className="menu-item mt-3" href="/cart">
                        Cart
                      </a>
                      {userInfo ? (
                        <>
                          <a
                            href={` ${
                              userInfo.user_type === "professional"
                                ? "appointment" //"/professionalDashboard"
                                : userInfo.user_type === "admin"
                                ? "adminDashboard"
                                : "enthusiastDashboard"
                            }`}
                            className="menu-item mt-3"
                          >
                            Dashboard
                          </a>
                          <a
                            onClick={() => handleLogout()}
                            className="menu-item mt-3"
                          >
                            Logout
                          </a>
                        </>
                      ) : (
                        <a
                          id="home"
                          className="menu-item mt-3"
                          href="#"
                          onClick={openModalSignIn}
                        >
                          Sign In
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <div
            className={` ${
              page === "other" ? "text-black2" : "text-white"
            } col-span-4 xl:col-span-4 lg:col-span-4 lg3:col-span-5 my-auto  text-xl font-normal`}
          >
            <NavLink
              to="/"
              activeClassName="activeMenu"
              className="mr-11 lg:mr-11 lg3:mr-7 align-sub hover:text-green1 hover:font-bold"
            >
              Home
            </NavLink>

            <a
              href="#"
              className="mr-11 lg:mr-11 lg3:mr-7 align-sub hover:text-green1 hover:font-bold"
              onClick={() => handleMenuClick("home")}
            >
              Services
            </a>
            <NavLink
              to="/professionals"
              activeClassName="activeMenu"
              className="mr-11 lg:mr-11 lg3:mr-7 align-sub hover:text-green1 hover:font-bold"
            >
              Professionals
            </NavLink>

            <a
              href="#"
              className="align-sub hover:text-green1 hover:font-bold"
              onClick={() => handleMenuClick("home")}
            >
              Contact Us
            </a>
            <div className="float-right ">
              <a href="/wishlist" className="align-text-top">
                <img
                  src={` ${
                    cartClr === "black"
                      ? "img/wishlist.png"
                      : "img/White-Heart.png"
                  } `}
                  className={` ${
                    cartClr === "black" ? "align-super" : "align-middle"
                  } inline-block h-[25px] w-[25px] mr-5 `}
                />
              </a>
              <a href="/cart" className="align-text-top">
                <img
                  src={` ${
                    cartClr === "black" ? "img/cart.png" : "img/cartW.jpg"
                  } `}
                  className={` ${
                    cartClr === "black" ? "align-super" : "align-middle"
                  } inline-block h-[20px] w-[20px] mr-5 `}
                />
              </a>
              {userInfo ? (
                <span className=" py-1.5 bg-green1 text-white rounded  shadowEffect hover:opacity-60">
                  <a
                    href={` ${
                      userInfo.user_type === "professional"
                        ? "appointment" //"/professionalDashboard"
                        : userInfo.user_type === "admin"
                        ? "adminDashboard"
                        : "enthusiastDashboard"
                    }`}
                    className="font-normal px-3 py-3 text-base"
                  >
                    Dashboard
                  </a>
                </span>
              ) : (
                <span className="float-right border border-white rounded py-1.5 hover:text-green1 hover:font-bold">
                  <a
                    href="/#"
                    onClick={openModalSignIn}
                    className="font-bold px-3 py-3 text-base"
                  >
                    Sign In
                  </a>
                </span>
              )}
            </div>
          </div>
        )}
      </div>
      <ToastContainer />

      <Model
        modalIsOpen={modalIsOpenForgot}
        closeModal={closeModalForgot}
        content={contentForgot()}
        dialogClassName="modal-width-signIn"
      />

      <Model
        modalIsOpen={modalIsOpenSignIn}
        closeModal={closeModalSignIn}
        content={contentSignIn()}
        dialogClassName="modal-width-signIn"
      />

      <Model
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        content={content()}
        dialogClassName="modal-width"
      />

      <Model
        modalIsOpen={modalIsOpenInterest}
        closeModal={closeModalInterest}
        content={<Interest />}
        dialogClassName="modal-width"
      />
    </div>
  );
}

export default OuterMenu;
