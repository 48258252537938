import React, { useState } from "react";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toastSuccess, toastError } from "../hooks/toaster";
import { json, useNavigate } from "react-router-dom";
import Spinner from "./spinner";

const CheckoutForm = ({ cartData, totalAmt, page, shippingName }) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const usertoken = localStorage.getItem("userToken");
  const stripe = useStripe();
  const elements = useElements();

  const [paymentError, setPaymentError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to handle the results after all API calls
  const handleApiResults = async (apiResults) => {
    // Your code to handle the results goes here
    //console.log("All API calls completed:", apiResults);
    let formData = {};
    if (page === "product") {
      formData["cart_id_array"] = apiResults;
      formData["name"] = shippingName;
    } else {
      formData["booking_id_array"] = apiResults;
    }
    try {
      const response = await axios.post(
        API_URL + page + "/add-payment/",
        formData,
        { headers: { authorization: "Bearer " + usertoken } }
      );
      if (response) {
        setLoading(false);
        toastSuccess(page + " booked successfully");
        navigate("/ThankYou");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
      toastError(
        error && error.response && error.response.message
          ? error.response.data.message
          : "Service not booked"
      );
    }
  };

  const executeApiCalls = async () => {
    try {
      const apiResults = await makeApiCalls();
      handleApiResults(apiResults);
    } catch (error) {
      console.error("Error during API calls:", error);
    }
  };

  const makeApiCalls = async () => {
    const results = [];
    let arr = [];
    for (const apiUrl of cartData) {
      const data = await fetchData(apiUrl);
      const data2 = await fetchData(apiUrl);
      let transformedItem = "";
      if (page === "product") {
        transformedItem = {
          cart_id: apiUrl._id,
          product_amount: apiUrl.price,
          network_fee_amount: apiUrl.professional_product_fee,
          token_platform: data2.token.id,
          // total_amount: parseInt(
          //   apiUrl.price + apiUrl.professional_product_fee,
          //   10
          // ),
          token_account: data.token.id,
        };
      } else {
        console.log(data2, "data 22");
        transformedItem = {
          booking_id: apiUrl._id,
          service_amount: apiUrl.orignal_price,
          token_platform: data2.token.id,
          network_fee_amount: apiUrl.professional_service_fee,
          // total_amount: parseInt(
          //   apiUrl.orignal_price + apiUrl.professional_service_fee,
          //   10
          // ),
          token_account: data.token.id,
        };
      }
      arr.push(transformedItem);

      results.push(data);
    }
    return arr;
  };

  const fetchData = async (url) => {
    const response = await stripe.createToken(elements.getElement(CardElement));
    //const data = await response.json();
    return response;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    }
    executeApiCalls();
    // let formData = {};
    // let arr = [];
    // if (page === "product") {
    //   if (shippingName) {
    //     formData["name"] = shippingName;
    //   }
    //   // const { token, error } = await stripe.createToken(
    //   //   elements.getElement(CardElement)
    //   // );
    //   const transformedArray = await Promise.all(
    //     cartData.map(async (item) => {
    //       let token = await stripe.createToken(
    //         elements.getElement(CardElement)
    //       );
    //       let transformedItem = {
    //         cart_id: item._id,
    //         total_amount: parseInt(
    //           item.price + item.professional_product_fee,
    //           10
    //         ),
    //         token: token.token.id,
    //       };
    //       arr.push(transformedItem);
    //     })
    //   );
    //   formData["cart_id_array"] = arr;
    // } else {
    //   executeApiCalls();

    //   formData["booking_id_array"] = arr;
    // }
    // try {
    //   const response = await axios.post(
    //     API_URL + page + "/add-payment/",
    //     formData,
    //     { headers: { authorization: "Bearer " + usertoken } }
    //   );
    //   if (response) {
    //     toastSuccess(page + " booked successfully");
    //     navigate("/ThankYou");
    //   }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    //   toastError(
    //     error && error.response && error.response.message
    //       ? error.response.data.message
    //       : "Service not booked"
    //   );
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {loading ? <Spinner /> : <button type="submit">Pay</button>}
      {paymentError && <div style={{ color: "red" }}>{paymentError}</div>}
    </form>
  );
};

export default CheckoutForm;
