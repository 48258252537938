import React from "react";

import { toast } from "react-toastify";

export function toastSuccess(message) {
  toast.success(message, {
    icon: () => (
      <img
        alt="notification"
        src="img/notification.svg"
        width={60}
        height={60}
      />
    ),
    theme: "colored",
  });
}

export function toastWarn(message) {
  toast.warn(message, {
    icon: () => (
      <img
        alt="notification"
        src="img/notification.svg"
        width={60}
        height={60}
      />
    ),
    theme: "colored",
  });
}

export function toastError(message) {
  toast.error(message, {
    icon: () => (
      <img alt="notification" src="img/warningW.svg" width={60} height={60} />
    ),
    theme: "colored",
  });
}
