import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../mobileFooter";
import Menu from "../menu";
import { UseMediaQuery } from "../../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "../spinner";

const AdminReport = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const API_URL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("userToken");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [professional, setProfessional] = useState();
  const [loading, setLoading] = useState(false);
  const [profSelected, setProfSelected] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(API_URL + "user/professional-user/", {
        headers: { authorization: "Bearer " + token },
      });
      if (response) {
        setProfessional(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setProfSelected(e.target.value);
  };

  const handleReset = () => {};

  const handleFilter = () => {};

  return (
    <div>
      <div>
        <div className="bg-white1 ">
          <div className="flex">
            <Menu />
            <div className="w-full ">
              <Header />
              <div
                className={` ${
                  matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
                } `}
              >
                <div className="font-bold ">Service Order Report</div>

                <div className="inline-block  mt-1">
                  <label className="ml-2">Professional : </label>
                  <select
                    name="member"
                    className="inline-block ml-2 p-2 border border-grey-9 rounded"
                    value={profSelected}
                    onChange={handleChange}
                  >
                    <option value="0">All</option>
                    {professional &&
                      professional.length > 0 &&
                      professional.map((prof) => {
                        return (
                          <option key={prof.user._id} value={prof.user._id}>
                            {prof.user.first_name + " " + prof.user.last_name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="inline-block  mt-1">
                  <button
                    onClick={handleFilter}
                    className="bg-green1 text-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                  >
                    Apply
                  </button>

                  <button
                    onClick={handleReset}
                    className="bg-white ml-2 border border-grey-9 rounded font-bold px-3 py-2"
                  >
                    Reset
                  </button>
                </div>

                <div class="bg-white mt-3 mb-3 rounded flex flex-col overflow-x-auto">
                  <div class="sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div class="overflow-x-auto">
                        <table class="min-w-full text-left text-sm font-light">
                          <thead class="border-b font-medium dark:border-neutral-500">
                            <tr>
                              <th scope="col" class="px-6 py-4">
                                Professional
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Customer
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Service
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Date
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Time
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Amount
                              </th>
                              <th scope="col" class="px-6 py-4">
                                Network Fees
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                Michael Torres
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                Julie Brown
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                Hair Cut
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                1 March, 2024
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                10:30 AM
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                $50
                              </td>
                              <td class="whitespace-nowrap px-6 py-2 font-medium">
                                $8.99
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {matches ? <Footer /> : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminReport;
