import React, { useState, useEffect } from "react";
import Header from "../header";
import Footer from "../mobileFooter";
import Menu from "../menu";
import axios from "axios";
import { UseMediaQuery } from "../../hooks/useMediaQuery";
import Spinner from "../spinner";
import DeleteModal from "../deleteModal";
import Model from "../model";
import { toastSuccess, toastError } from "../../hooks/toaster";

const ProfessionalListAdmin = () => {
  const matches = UseMediaQuery("(max-width: 992px)");
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [receivedData, setReceivedData] = useState([]);
  const data = [];
  const FRONT_URL = process.env.REACT_APP_FRONT_URL;
  const [modalIsOpenDelete, setIsOpenModalDelete] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleDelete = async () => {
    setLoadingDelete(true);
    try {
      await axios
        .delete(API_URL + "user/delete-user/" + deleteId, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          fetchData();
          setLoadingDelete(false);
          toastSuccess("User deleted successfully");
          setIsOpenModalDelete(false);
        })
        .catch(function (error) {
          toastError(error.response.data.message || "User not deleted");
          console.log(error);
          setLoadingDelete(false);
        });
    } catch (error) {
      setLoadingDelete(false);
      toastError(error || "User not deleted");
      console.error("Error:", error);
    }
  };

  const openModalDelete = (id) => {
    setDeleteId(id);
    setIsOpenModalDelete(true);
  };

  const closeModalDelete = () => {
    fetchData();
    setIsOpenModalDelete(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    let url = "user/professional-user";
    await axios
      .get(API_URL + url + "?limit=500", {
        headers: {},
      })
      .then(function (response) {
        setLoading(false);
        setReceivedData(response.data);
        //navigate("/salonListing", { state: { data: response.data } });
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } `}
            >
              <div class="bg-white mt-3 mb-3 rounded flex flex-col overflow-x-auto">
                <div class="sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                    <div class="overflow-x-auto">
                      <table class="min-w-full text-left text-sm font-light">
                        <thead class="border-b font-medium dark:border-neutral-500">
                          <tr>
                            <th scope="col" class="px-6 py-4">
                              Name
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Email
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Phone Number
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Profile
                            </th>
                            <th scope="col" class="px-6 py-4">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {receivedData.length > 0 ? (
                            receivedData.map((item, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap px-6 py-2 font-medium">
                                  {item.user.first_name +
                                    " " +
                                    item.user.last_name}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2 font-medium">
                                  {item.user.email}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2 font-medium">
                                  {item.user.phone_number}
                                </td>
                                <td className="whitespace-nowrap px-6 py-2 font-medium">
                                  <a
                                    target="_blank"
                                    href={`${
                                      FRONT_URL + item.user.profile_name
                                    }`}
                                  >
                                    View Profile
                                  </a>
                                </td>
                                <td>
                                  <button
                                    onClick={(id) =>
                                      openModalDelete(item.user._id)
                                    }
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={5} className="text-center px-6 py-2">
                                No records found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>

      <Model
        modalIsOpen={modalIsOpenDelete}
        closeModal={closeModalDelete}
        content={
          <DeleteModal
            closeModalDelete={closeModalDelete}
            title="Professional"
            handleDelete={handleDelete}
            loading={loadingDelete}
          />
        }
        dialogClassName="modal-width-30"
      />
    </div>
  );
};

export default ProfessionalListAdmin;
