import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import Footer from "./mobileFooter";
import Menu from "./menu";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import axios from "axios";
import Spinner from "./spinner";
import { toastSuccess, toastError } from "../hooks/toaster";
import DatePicker from "react-horizontal-datepicker";
import Select from "react-select";
import { convertTime } from "../hooks/commonFunctions";
import PaymentSummary from "./paymentSummary";
import { generateRandomEmail } from "../hooks/commonFunctions";

const AddAppointment = () => {
  const navigate = useNavigate();
  const matches = UseMediaQuery("(max-width: 992px)");
  const token = localStorage.getItem("userToken");
  const API_URL = process.env.REACT_APP_API_URL;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [serviceData, setServiceData] = useState();
  const [memberData, setMemberData] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDay, setLoadingDay] = useState(false);
  const [memberSelected, setMemberSelected] = useState();
  const [eventData, setEventData] = useState();
  const [member, setMember] = useState();
  const [memberName, setMemberName] = useState();
  const [serviceArr, setServiceArr] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const [slotFullData, setSlotFullData] = useState();
  const [breakData, setBreakData] = useState();
  const [disableData, setDisableData] = useState();
  const [slotData, setSlotData] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [loadingNext, setLoadingNext] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    phone_number: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [customerId, setCustomerId] = useState();
  const [checkoutStatus, setCheckoutStatus] = useState(false);
  const [selectedOption, setSelectedOption] = useState("cash");
  const [loadingApp, setLoadingApp] = useState(false);
  const [cartData, setCartData] = useState();
  const [timeErr, setTimeErr] = useState(false);
  const [onLeaveStatus, setOnLeaveStatus] = useState(false);

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    fetchServiceData();
    fetchData();
  }, []);

  const fetchServiceData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "service/get-all-professional-service/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setServiceData(response.data);
        const newArray = response.data.map((item) => ({
          value: item._id,
          label:
            item.service_name +
            " | " +
            item.period +
            " min | " +
            item.price +
            "$",
        }));
        setServiceArr(newArray);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        API_URL + "member/get-all-member/" + userInfo.user_id,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setMemberData(response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleMember = (e) => {
    const index = memberData.findIndex(
      (user) => user.item._id === e.target.value
    );
    setMemberName(
      memberData[index].item.first_name + " " + memberData[index].item.last_name
    );
    setMember(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setCount(count + 1);
  };

  const calculateSlots = (startTime, endTime) => {
    const timeSlots = [];
    const interval = 30 * 60 * 1000; // 30 minutes in milliseconds

    let currentTime = new Date(startTime);

    while (currentTime <= endTime) {
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      timeSlots.push(formattedTime);

      currentTime.setTime(currentTime.getTime() + interval);
    }
    return timeSlots;
  };

  const checkBreakHour = (slot_time, breakTimes) => {
    let status = false;
    breakTimes.map((breakT) => {
      const startTime = convertTime(breakT.start_time);
      const endTime = convertTime(breakT.end_time);
      const checkTime = convertTime(slot_time);
      const isBetween = checkTime >= startTime && checkTime < endTime;
      if (isBetween === true) {
        status = isBetween;
      }
    });
    return status;
  };

  const selectedDay = async (val) => {
    const currentDate = new Date(val);
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    setSelectedDate(val);
    setCount(count + 1);

    setLoadingDay(true);
    try {
      const response = await axios.get(
        API_URL + "slot/get-slot-for-customer/" + formattedDate + "/" + member,
        { headers: { authorization: "Bearer " + token } }
      );
      if (response) {
        setLoadingDay(false);
        setSlotFullData(response.data);
        setBreakData(response.data.breakTimes);
        setDisableData(response.data.disableSlot);
        if (response.data.slot.on_off === "off") {
          setSlotData([]);
          setOnLeaveStatus(true);
        } else {
          const startTime = convertTime(response.data.slot.start_time);
          const endTime = convertTime(response.data.slot.end_time);

          const slotArr = calculateSlots(startTime, endTime);
          const finalSlotArr = [];
          slotArr.map((slot) => {
            if (response.data.breakTimes.length > 0) {
              if (checkBreakHour(slot, response.data.breakTimes)) {
              } else {
                finalSlotArr.push(slot);
              }
            } else {
              if (response && response.data && response.data.slot) {
                finalSlotArr.push(slot);
              }
            }
          });

          //now check for disabled slots
          const finalSlotArrAfterDisable = [];
          finalSlotArr.map((slot) => {
            if (response.data.disableSlot.length > 0) {
              if (checkDisableSlot(response.data.disableSlot, slot)) {
              } else {
                finalSlotArrAfterDisable.push(slot);
              }
            } else {
              if (response && response.data && response.data.slot) {
                finalSlotArrAfterDisable.push(slot);
              }
            }
          });

          setSlotData(finalSlotArrAfterDisable);
        }
        setLoading(false);
        setCount(count + 1);
      }
    } catch (error) {
      setSlotData([]);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const checkDisableSlot = (disableDataList, slot_time) => {
    let status = false;
    disableDataList.map((timeString) => {
      const timeArray = timeString.split(", ");

      const startTime = convertTime(timeArray[0]);
      const endTime = convertTime(timeArray[1]);
      const checkTime = convertTime(slot_time);
      const isBetween = checkTime >= startTime && checkTime < endTime;
      if (isBetween === true) {
        status = isBetween;
      }
    });
    return status;
  };

  function bookedDiv() {
    return (
      <div className="text-center mt-3">
        <img
          alt="calendar"
          src="img/calenderCross.png"
          className="h-[50px] w-[50px] mx-auto"
        />
        <span className="mt-3 block font-bold">
          {memberName} is fully booked today
        </span>
        <span className="block text-grey3 text-sm">
          Please select another date or try with other professionals
        </span>
      </div>
    );
  }

  const handleSelectTimeSlot = (time) => {
    setSelectedTimeSlot(time);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name.trim()) {
      errors.first_name = "Name is required";
    }

    if (!formData.phone_number.trim()) {
      errors.phone_number = "Phone Number is required";
    }

    // if (!formData.email.trim()) {
    //   errors.email = "Email is required";
    // }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      setLoadingAdd(true);
      formData.email = generateRandomEmail();
      try {
        await axios
          .post(API_URL + "user/offline-signup", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            if (
              response &&
              response.data &&
              response.data.user &&
              response.data.user.user_id
            ) {
              setCustomerId(response.data.user.user_id);
            } else {
              if (response && response.data && response.data.existingUser) {
                setCustomerId(response.data.existingUser._id);
              }
            }

            setLoadingAdd(false);
          })
          .catch(function (error) {
            console.log(error);
            setLoadingAdd(false);
          });
      } catch (error) {
        setLoadingAdd(false);
        console.error("Error:", error);
      }
    }
  };

  const payCash = async () => {
    try {
      setLoadingApp(true);
      const bookingIds = cartData.map((item) => item._id);
      let formDataNew = {};
      formDataNew["booking_ids"] = bookingIds;

      const response1 = await axios.put(
        API_URL + "service/booking-status-for-offline",
        formDataNew,
        {
          headers: {
            authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        }
      );
      if (response1) {
        toastSuccess("Appointment created successfully");
        window.location.reload();
        navigate("/addAppointment");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleAdd = async () => {
    if (checkoutStatus) {
      if (selectedOption === "online") {
        //navigate("/cart");
      } else {
      }
    } else {
      try {
        if (!selectedTimeSlot) {
          setTimeErr(true);
        } else {
          setLoadingNext(true);

          var isoDate = new Date(
            selectedDate.getTime() - selectedDate.getTimezoneOffset() * 60000
          ).toISOString();
          let formData = {};
          const index = serviceData.findIndex(
            (user) => user._id === selectedOptions.value
          );

          formData["customer_user_id"] = customerId;
          formData["seller_user_id"] = userInfo.user_id;
          formData["service_id"] = selectedOptions.value;
          formData["price"] = serviceData[index].price;
          formData["day"] = isoDate;
          formData["other_charges"] = 0;
          formData["slot_id"] = slotFullData.slot._id;
          formData["booking_time"] = selectedTimeSlot;
          formData["member_id"] = member;
          formData["booking_user_type"] = "offline";
          const response = await axios.post(
            API_URL + "service/add-service-booking",
            formData,
            {
              headers: {
                authorization: "Bearer " + localStorage.getItem("userToken"),
              },
            }
          );
          if (response) {
            setLoadingNext(false);
            setCheckoutStatus(true);
            toastSuccess("Service added to cart successfully");
            getCartData();
          }
        }
      } catch (error) {
        setLoadingNext(false);
        console.error("Error fetching data:", error);
      }
    }
  };

  const getCartData = async () => {
    let cartResponse = "";
    try {
      cartResponse = await axios.get(
        API_URL + "service/get-booking-service/" + customerId,
        { headers: { authorization: "Bearer " + token } }
      );
      if (cartResponse) {
        setCartData(cartResponse.data);
        setLoadingApp(false);
      }
    } catch (error) {
      setLoadingApp(false);
      console.error("Error fetching data:", error);
    }
  };

  const addAnotherService = () => {
    window.location.reload();
    navigate("/addAppointment");
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setCount(count + 1);
  };

  return (
    <div className="">
      <div className="bg-white1 ">
        <div className="flex">
          <Menu />
          <div className="w-full ">
            <Header />
            <div
              className={` ${
                matches ? "mx-2 my-2 min-h-screen" : "mx-4 my-4"
              } bg-white p-4`}
            >
              <div className="font-bold text-xl">
                Add Appointment for walk-in customers
              </div>
              <div className="mt-2">
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <label className="text-black text-base w-full mt-3">
                        Name :
                      </label>
                      <input
                        type="text"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                        placeholder="Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                      />
                      {errors.first_name && (
                        <span className="text-red text-xs">
                          {errors.first_name}
                        </span>
                      )}
                    </div>

                    <div>
                      <label className="text-black text-base w-full mt-3">
                        Phone Number :
                      </label>
                      <input
                        type="text"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                        placeholder="Phone Number"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                      />
                      {errors.phone_number && (
                        <span className="text-red text-xs block mt-2">
                          {errors.phone_number}
                        </span>
                      )}
                    </div>

                    {/* not needed so commented */}
                    {/* <div>
                      <label className="text-black text-base w-full mt-3">
                        Email :
                      </label>
                      <input
                        type="text"
                        className="border border-grey1 p-2 mt-1 placeholder-gray-500 text-gray-900 text-base w-full rounded "
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className="text-red text-xs block mt-2">
                          {errors.email}
                        </span>
                      )}
                    </div> */}
                  </div>
                  {!loadingAdd ? (
                    <input
                      type="submit"
                      value="Add"
                      name="Add"
                      className="rounded mt-3 font-bold bg-green1 text-white py-2.5 px-4 text-base shadowEffect hover:opacity-60"
                    />
                  ) : (
                    <Spinner />
                  )}
                </form>
              </div>

              {!loading ? (
                <div className="">
                  <div className="mt-4">
                    <div className="font-bold">Select Service</div>
                    <Select
                      name="startTimeForEdit"
                      className={` ${matches ? "" : "w-4/12"} p-2 `}
                      id="startTimeForEdit"
                      options={serviceArr}
                      value={selectedOptions}
                      onChange={handleSelectChange}
                      //isMulti
                    />
                  </div>

                  {selectedOptions && selectedOptions.value ? (
                    <div>
                      <div className="font-bold mt-4 mb-1">Select Member</div>
                      {memberData && memberData.length > 0 ? (
                        <div>
                          <select
                            className="rounded border border-grey9 p-2"
                            onChange={handleMember}
                          >
                            <option>Select Member</option>
                            {memberData.map((members) => {
                              let member = members.item;
                              return (
                                <option value={member._id}>
                                  {member.first_name + " " + member.last_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {memberName ? (
                    <div className="mt-4">
                      <DatePicker
                        color="#539d90"
                        onChange={(date) => handleDateChange(date)}
                        onClick={(date) => handleDateChange(date)}
                        getSelectedDay={(date) => selectedDay(date)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <Spinner />
              )}

              {member ? (
                <div className="mt-8">
                  {loadingDay ? (
                    <Spinner />
                  ) : (
                    <>
                      {console.log(slotData, "himaniii")}
                      {slotData && slotData.length > 0 ? (
                        <div>
                          <div className=" max-w-[800px] inline-block w-full">
                            <>
                              <div className="overflow-auto whitespace-nowrap pb-3">
                                {slotData.map((slot) => {
                                  return (
                                    <>
                                      <button
                                        onClick={() =>
                                          handleSelectTimeSlot(slot)
                                        }
                                        className={` ${
                                          selectedTimeSlot === slot
                                            ? "bg-grey9"
                                            : ""
                                        } w-auto ml-2 text-left hover:bg-grey9 cursor-pointer mt-2 p-2 rounded border border-grey-7`}
                                      >
                                        {slot}
                                      </button>
                                    </>
                                  );
                                })}
                              </div>
                              <div className="">
                                {loadingNext ? <Spinner /> : <></>}
                              </div>
                            </>
                          </div>

                          {checkoutStatus ? (
                            <button
                              type="button"
                              onClick={() => addAnotherService()}
                              className={` ${
                                matches ? "mt-2" : "mt-4"
                              } block text-base border border-grey9 py-2 px-3 rounded shadowEffect hover:opacity-60`}
                            >
                              Add Another Service
                            </button>
                          ) : (
                            ""
                          )}

                          <div className="mt-3">
                            <label>
                              <input
                                className="mr-1.5"
                                type="radio"
                                value="cash"
                                checked={selectedOption === "cash"}
                                onChange={handleOptionChange}
                              />
                              Pay in Cash
                            </label>

                            <label>
                              <input
                                className="ml-4 mr-1.5"
                                type="radio"
                                value="online"
                                checked={selectedOption === "online"}
                                onChange={handleOptionChange}
                              />
                              Pay Online
                            </label>
                          </div>
                          {timeErr ? (
                            <div className="text-red mt-2">
                              Please select time
                            </div>
                          ) : (
                            ""
                          )}
                          {checkoutStatus === false ? (
                            <button
                              type="button"
                              onClick={() => handleAdd()}
                              className={` ${
                                checkoutStatus ? "mr-2" : ""
                              }  block mt-4 text-base font-bold bg-green1 text-white py-2 px-3 rounded shadowEffect hover:opacity-60`}
                            >
                              {checkoutStatus ? "Checkout" : "Add"}
                            </button>
                          ) : (
                            ""
                          )}
                          <div>
                            {cartData && selectedOption ? (
                              <div>
                                <PaymentSummary
                                  userId={customerId}
                                  paymentMode={selectedOption}
                                  payCash={payCash}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : (
                        <> {bookedDiv()}</>
                      )}
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            {matches ? <Footer /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAppointment;
