import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import OuterMenu from "./outerMenu";
import OuterFooter from "./outerFooter";
import { UseMediaQuery } from "../hooks/useMediaQuery";
import Spinner from "./spinner";

function ProfessionalList() {
  const matches = UseMediaQuery("(max-width: 992px)");
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [receivedData, setReceivedData] = useState([]);
  const data = [];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    let url = "user/professional-user";
    await axios
      .get(API_URL + url + "?limit=500", {
        headers: {},
        credentials: 'include',
      })
      // .get(API_URL + url + "&limit=100&page=1", {
      //   headers: {},
      // })
      .then(function (response) {
        setLoading(false);
        setReceivedData(response.data);
        //navigate("/salonListing", { state: { data: response.data } });
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const description = (text) => {
    const words = text.split(" ");

    // Display only the first 5 words
    const truncatedText = words.slice(0, 4).join(" ");
    return words.length > 5 ? `${truncatedText} ...` : truncatedText;
  };

  if (receivedData.length > 0) {
    for (let i = 0; i < receivedData.length; i++) {
      data.push(
        // <a href={`/salonDetail?id=${receivedData[i].user._id}`}>
        <a
          href={`${
            receivedData[i].user.profile_name
              ? "/" + receivedData[i].user.profile_name
              : "#"
          }`}
        >
          <div className="border border-grey9 rounded px-2 py-2">
            <div
              className={`${
                matches ? "image-container-120" : "image-container-310"
              } `}
            >
              <img
                alt="profile"
                src={` ${
                  receivedData[i].user.profile_picture
                    ? receivedData[i].user.profile_picture
                    : "img/img7.png"
                }`}
                className="rounded"
                // className={` ${matches ? "h-[120px]" : "h-[310px]"} w-[310px]`}
              />
            </div>
            <div className="salonName mt-1 text-black2 lg:text-2xl md:text-2xl sm:text-2xl xs:text-xl xxs:text-xl">
              {receivedData[i].user && receivedData[i].user.salon_name
                ? receivedData[i].user.salon_name
                : receivedData[i].user.first_name +
                  " " +
                  receivedData[i].user.last_name}
            </div>
            {/* <div className="lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-sm text-grey3">
              {receivedData[i].user.description
                ? description(receivedData[i].user.description)
                : "\u00A0"}
            </div> */}
            <div className="">
              <img
                alt="work"
                src="img/rating.png"
                className="inline-block align-text-top"
              />
              <span className="ml-1 inline-block lg:text-base md:text-base sm:text-base xs:text-sm xxs:text-xs">
                {receivedData[i].rating && receivedData[i].rating.avgRating
                  ? receivedData[i].rating.avgRating +
                    " (" +
                    receivedData[i].rating.totalRatings +
                    ")"
                  : 0}
              </span>
            </div>
            <div className="">
              <span className="inline-block mt-2 bg-grey11 px-2 py-2 lg:text-xs md:text-xs sm:text-xs xs:text-[10px] xxs:text-[10px] rounded">
                Men's haircut
              </span>
              <span className="inline-block mt-2 ml-1 bg-grey11 px-2 py-2 lg:text-xs md:text-xs sm:text-xs xs:text-[10px] xxs:text-[10px] rounded">
                Kid's haircut
              </span>
              <span className="inline-block mt-2 ml-1 bg-grey11 px-2 py-2 lg:text-xs md:text-xs sm:text-xs xs:text-[10px] xxs:text-[10px] rounded">
                15+
              </span>
            </div>
          </div>
        </a>
      );
    }
  }

  return (
    <div>
      <div className="mt-3 border-b border-grey2 pb-2">
        <div className="pb-2">
          <OuterMenu page="other" cartClr="black" />
        </div>
      </div>
      <div className="xl:px-44 lg:px-44 lg3:px-36 lg2:px-36 md:px-28 sm:px-20 xs:px-8 xxs:px-6 mt-10">
        <div className="font-bold xl:text-4xl lg:text-4xl lg3:text-3xl lg2:text-2xl md:text-2xl sm:text-xl xs:text-xl xxs:text-xl">
          Top-rated hairstylists offering the best services across the United
          States
        </div>

        {/* <div className="block mt-4">Over 1000 salon</div> */}

        <div className="grid grid-cols-4 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 xxs:grid-cols-2 mt-4 gap-3">
          {data.length > 0 ? (
            data
          ) : (
            <div className="text-2xl text-center font-bold col-span-4">
              <Spinner />
            </div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <OuterFooter />
      </div>
    </div>
  );
}

export default ProfessionalList;
